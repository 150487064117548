<template>
    <div class="row mx-0 bg-white br-8 p-3 mb-4">
        <p class="col-12 px-0 text-general mb-3 f-600 f-18">
            Recoge las recompensas de tus logros obtenidos
        </p>
        <div class="col-12 px-0 scroll-none overflow-auto pb-4">
            <div class="mt-2 a-center" style="display:inline-flex;">
                <div v-for="(lg, l) in logros" :key="l" :class="`card-logro ${tipoBackground(lg.background)} p-1 mr-3`">
                    <div class="inside w-100 h-100 br-9">
                        <div class="radial-background br-5 h-100 w-100 d-flex flex-column justify-content-start">
                            <p class="text-white title-reward font-omnes-light text-center px-2 mt-2">
                                {{ lg.titulo_logo }}
                            </p>
                            <div class="row mx-0 justify-center">
                                <div class="space-reward text-center">
                                    <img src="/img/gaming/i_moneda.svg" height="40" />
                                    <p class="col tres-puntos pl-4 lh-16">
                                        + 45
                                    </p>
                                </div>
                            </div>
                            <div class="row mx-0 justify-center mt-3">
                                <div class="space-reward text-center">
                                    <img src="/img/gaming/i_gema.svg" height="40" />
                                    <p class="col tres-puntos pl-4 lh-16">
                                        + 1
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="btn-red-gr px-4 font-omnes position-absolute">
                        RECOGER
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data(){
        return {
            logros: [
                {
                    titulo_logo: 'Me registro', background: 1
                },
                {
                    titulo_logo: 'Completé mi registro', background: 2
                },
                {
                    titulo_logo: 'Asocié mis redes', background: 3
                },
                {
                    titulo_logo: 'Mi primera compra', background: 4
                },
                {
                    titulo_logo: 'Ganancias por mis compras', background: 5
                },
                {
                    titulo_logo: 'Compras de mis referediso', background: 6
                },
                {
                    titulo_logo: 'Compras de mis referidos de 2do nivel', background: 7
                }
            ]
        }
    },
    methods: {
        tipoBackground(tipo){
            switch (tipo){
            case 1:
                return 'bg-radial-01';
            case 2:
                return 'bg-radial-02';
            case 3:
                return 'bg-radial-03';
            case 4:
                return 'bg-radial-04';
            case 5:
                return 'bg-radial-05';
            case 6:
                return 'bg-radial-06';
            case 7:
                return 'bg-radial-07';
            }
        }
    }
}
</script>

<style lang="scss" scoped>

.btn-red-gr{
    height: 36px;
    background-image: linear-gradient(to bottom, #FFAAAA, #F27777, #F86464, #F23E3E);
    border-radius: 12px;
    color: #ffffff;
    border: none;
    cursor: pointer;
    display: flex;
    font-size: 16px;
    align-items: center;
    justify-content: center;
    &:hover{
        box-shadow: 0 1px 4px #00000063;
    }
    &:focus{
        outline: none;
    }
}


.card-logro{
    width: 144px;
    height: 170px;
    border-radius: 9px;
    background-color: #F2CE4A;
    position: relative;
    font-size: 14px;
    .inside{
        .radial-background{
            .title-reward{
                height: 65px;
            }
            .space-reward{
                min-width: 80px;
                max-width: 180px;
                height: 24px;
                color: #FFFFFF;
                font-family: 'Omnes';
                display: flex;
                position: relative;
                align-items: center;
                justify-content: center;
                font-size: 18px;
                border-radius: 8px;
                font-weight: 600;
                background-image: linear-gradient(to bottom, #0000008C, #00000029);
                img{
                    position: absolute; 
                    left: -11px;
                    top: -8px;

                }
            }
        }
    }
    .btn-red-gr{
        bottom: -22px;
        left: 10px;
    }
}

.bg-radial-01{
    .inside{
        border: 3px solid #0000006e;
        .radial-background{
            background: radial-gradient(#FFF7B1, #FFCC80, #DB9437);
        }
    }
}

.bg-radial-02{
    .inside{
        border: 3px solid #7DBB90;
        .radial-background{
            background: radial-gradient(#E2FFEA, #BDE2C7, #7DBB90);
        }
    }
}

.bg-radial-03{
    .inside{
        border: 3px solid #E25B45;
        .radial-background{
            background: radial-gradient(#FFC9C9, #FF8888, #E25B45);
        }
    }
}

.bg-radial-04{
    .inside{
        border: 3px solid #0ABF88;
        .radial-background{
            background: radial-gradient(#DFFFF5, #61E1BA, #0ABF88);
        }
    }
}

.bg-radial-05{
    .inside{
        border: 3px solid #0ABF88;
        .radial-background{
            background: radial-gradient(#BAF6FF, #31E3FF, #009EC7);
        }
    }
}

.bg-radial-06{
    .inside{
        border: 3px solid #FF5DA7;
        .radial-background{
            background: radial-gradient(#FFB1ED, #FFA5EA, #FF5DA7);
        }
    }
}


.bg-radial-07{
    .inside{
        border: 3px solid #54505E;
        .radial-background{
            background: radial-gradient(#9D99A8, #9C98A7, #54505E);
        }
    }
}

.space-no-reclamadas{
    max-width: 130px;
    height: 24px;
    border-radius: 4px;
    color: #000000;
    position: relative;
    background-color: #F6F9FB;
    img{
        height: 33px;
        position: absolute;
        left: -15px;
        top: -5px;
    }
}

</style>